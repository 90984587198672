<template>
  <AbstractFilter
    :on-filter="filterNativeCampaigns"
    :on-filter-reset="resetFilter"
    :create-new-label="$t('donBox.nativeCampaign.createNewLabel')"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="native_campaign_new"
    filter-panel-class="filter-panel-native-campaign"
  >
    <Select
      v-model="filter.status"
      :options="statuses"
      id="filter_status"
      :label="$t('donBox.nativeCampaign.filter.status')"
    />
    <Input
      v-model="filter.agency"
      id="filter_agency"
      :label="$t('donBox.nativeCampaign.filter.agency')"
    />
    <Input
      v-model="filter.client"
      id="filter_client"
      :label="$t('donBox.nativeCampaign.filter.client')"
    />
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('donBox.nativeCampaign.filter.title')"
    />
    <Checkbox
      id="isPaid"
      :value="!filter.isFree"
      @input="(value) => filter.isFree = !value"
      :label="$t('donBox.nativeCampaign.paidCampaign')"
    />
    <Datepicker
      type="date"
      v-model="filter.from"
      id="filter_from"
      :label="$t('donBox.nativeCampaign.filter.from')"
    />
    <Datepicker
      type="date"
      v-model="filter.to"
      id="filter_to"
      :label="$t('donBox.nativeCampaign.filter.to')"
    />
    <Input
      v-model="filter.url"
      id="filter_url"
      :label="$t('donBox.nativeCampaign.filter.url')"
    />
    <Input
      v-model="filter.salesman"
      id="filter_salesman"
      :label="$t('donBox.nativeCampaign.filter.salesman')"
    />
    <Input
      v-model="filter.orderId"
      id="filter_orderId"
      :label="$t('donBox.nativeCampaign.filter.orderId')"
    />
    <template #afterResetButtons>
      <div />
      <div />
      <div>
        <ButtonCsvExport
          v-if="showExportButton"
          :get-csv-data="exportCampaigns"
          btn-class="btn"
          filename="native-campaigns"
        />
      </div>
      <Checkbox
        id="auto-refresh"
        v-model="autoRefresh"
        class="auto-refresh"
        :label="$t('auto_refresh')"
      />
    </template>
  </AbstractFilter>
</template>

<script>
import Datepicker from '@/components/form/Datepicker'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import NativeCampaignManagementFilterModel from '@/model/NativeCampaignManagementFilterModel'
import AbstractFilter from '@/components/filter/AbstractFilter'
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'
import Checkbox from '@/components/form/Checkbox'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'NativeCampaignFilter',
  components: {
    AbstractFilter,
    Input,
    Datepicker,
    Select,
    Checkbox,
    ButtonCsvExport
  },
  data () {
    return {
      filter: this._.cloneDeep(NativeCampaignManagementFilterModel),
      statuses: [
        { id: 'INACTIVE', title: 'INACTIVE' },
        { id: 'ACTIVE', title: 'ACTIVE' },
        { id: 'DONE', title: 'DONE' },
        { id: 'PAUSED', title: 'PAUSED' }
      ],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.NATIVE_CAMPAIGN_MANAGEMENT_PERMISSIONS
    }
  },
  computed: {
    autoRefresh: {
      get () {
        return this.$store.getters['nativeCampaignManagement/autoRefresh']
      },
      set (newValue) {
        this.$store.commit('nativeCampaignManagement/setAutoRefresh', newValue)
      }
    },
    showExportButton () {
      return this.$store.getters['user/hasPermission'](this.requiredPermissions.exportButton)
    }
  },
  methods: {
    filterNativeCampaigns () {
      this.$store.commit('nativeCampaignManagement/setCurrentPage', 1)
      this.$store.commit('nativeCampaignManagement/setFilter', this.filter)
      this.$store.dispatch('nativeCampaignManagement/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(NativeCampaignManagementFilterModel)
      this.$store.commit('nativeCampaignManagement/setFilter', this.filter)
      this.$store.dispatch('nativeCampaignManagement/fetch')
    },
    async exportCampaigns () {
      return this.$store.dispatch('nativeCampaignManagement/exportCampaigns')
    }
  },
  created () {
    this.filter = this.$store.getters['nativeCampaignManagement/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-native-campaign {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.auto-refresh {
  max-width: rem(100px);
}
</style>
